import styled from "styled-components";

export const StyledButton = styled.button`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #1976d2;
    color: #fff;
    font-size: 14px;
    margin: 1rem 0;
    cursor: pointer;
`;