import logoImg from "../assets/images/logo.png";

export default function Navbar({
    centralize = false,
    marginTop = true
}) {
    return (
        <header
            style={{
                marginTop: marginTop ? '5%' : '4%',
                display: 'flex',
                justifyContent: centralize ? 'center' : 'flex-start',
            }}
        >
            <img src={logoImg} alt="Ride for you app logo" width={'100px'} />
        </header>
    )
}