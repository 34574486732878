import { Container, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

import { CandidateComponent } from "../components/CandidateComponent";
import Navbar from "../components/Navbar";
import OptionButton from "../components/OptionButton";
import { RideComponent } from "../components/RideComponent";
import WheelAndHamster from "../components/WheelAndHamster";
import AcceptModal from "../components/modal/TransictionModal";
import { AuthContext } from "../contexts/AuthContext";
import { api } from "../services/api";

export default function RidesRider() {
  const [page, setPage] = useState(true);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const { data, error, isLoading, mutate } = useSWR(
    "driver_rides",
    async (url) => {
      const response = await api.request({
        url: "ride/rides",
        method: "get",
      });
      return response.data;
    }
  );

  const [nearRide, setNearRide] = useState(0);
  const {
    data: ridesAvailable,
    error: error_ridesAvailable,
    isLoading: isLoading_ridesAvailable,
  } = useSWR(
    `ride/rider/available${nearRide ? "?near=" + nearRide : ""}`,
    async (url) => {
      const response = await api.request({ url, method: "get" });

      return response.data;
    }
  );

  useEffect(() => {
    console.log(
      "Error: ",
      error,
      "Error_ridesAvailable: ",
      error_ridesAvailable
    );
    if (error || error_ridesAvailable) {
      console.log(error, error_ridesAvailable);
      navigate("/profile");
      return;
    }
  }, [error, error_ridesAvailable]);

  const [rideId, setRideId] = useState(null);
  const handleOpenAcceptPopup = (ride_id) => {
    setOpenPopup(true);
    setRideId(ride_id);
  };

  const handleCandidate = async () => {
    try {
      await api.request({
        url: `ride/${rideId}/candidate`,
        method: "post",
      });

      toast.success("Candidated");
      setOpenPopup(false);

      window.location.reload();
    } catch (error) {
      if (error.response.data.response.message) {
        toast.error(error.response.data.response.message);
      }
    }
  };

  const [agent, setAgent] = useState("unknown");
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }
  useEffect(() => {
    setAgent(getMobileOperatingSystem());
  }, []);

  const finishRide = async (ride) => {
    await api.patch(`ride/${ride.id}`, { status: "DONE" });
    mutate("driver_rides");
  };

  return (
    <>
      <AcceptModal
        text="The Passenger of a ride must accept it!"
        open={openPopup}
        setOpen={setOpenPopup}
        acceptFunction={handleCandidate}
        accept_text="Ok"
        cancel_text="Cancel"
        secondAcceptFunction={() => {
          setOpenPopup(false);
        }}
      />
      <Navbar marginTop={false} centralize={true} />
      <Container
        style={{
          padding: "0 4.5rem !important",
          marginBottom: "15vh",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="h5"
          gutterBottom
        >
          View My Rides
        </Typography>
        <OptionButton
          state={page}
          first_opt={"Rides Confirmed"}
          second_opt={"Accept a Ride"}
          handleState={setPage}
        />

        {isLoading && isLoading_ridesAvailable && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "7rem",
            }}
          >
            <WheelAndHamster />
          </div>
        )}

        {page &&
          !isLoading &&
          data &&
          data.rides &&
          data.rides.length > 0 &&
          data.rides.map((ride, idx) => {
            const destAddress = ride.destination.address.replace(" ", "+");
            return (
              <RideComponent
                key={ride.id}
                ride={ride}
                name_ride={ride.passenger.name}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{ borderRadius: "10px 0 0 10px" }}
                    onClick={() => {
                      finishRide(ride);
                    }}
                    className="btn_action_candidate_first"
                  >
                    Finalizar
                  </button>
                  <button
                    style={{ borderRadius: "0" }}
                    onClick={() => {
                      navigate("/chat", { state: { ride } });
                    }}
                    className="btn_action_candidate_first"
                  >
                    Chat
                  </button>
                  <a
                    href={
                      agent == "iOS"
                        ? `http://maps.apple.com/?q=${destAddress}`
                        : `https://maps.google.com/?q=${destAddress}`
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="btn_action_candidate_second"
                  >
                    Abrir no mapa
                  </a>
                </div>
              </RideComponent>
            );
          })}

        {!page && data && data.rides && data.rides.length > 0 && (
          <>
            <br />
            <label>
              <input
                type="checkbox"
                checked={!!nearRide}
                onChange={() => {
                  setNearRide(!nearRide ? data.rides[0].id : 0);
                }}
              />
              Filter rides close to the destination
            </label>
          </>
        )}

        {!page &&
          !isLoading_ridesAvailable &&
          ridesAvailable &&
          ridesAvailable.length > 0 &&
          ridesAvailable
            .sort((a, b) => b.id - a.id)
            .map((ride, idx) => (
            <CandidateComponent
              key={idx}
              candidate={ride.passenger}
              first_handle={handleOpenAcceptPopup}
              ride_data={ride}
            />
          ))}
      </Container>
    </>
  );
}
