import { api } from './api';

async function signInRequest(data) {
    try {
        const response = await api.post('auth', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response.data
    }
    catch (error) {
        return {
            error: error.response.data.message
        }
    }
}


async function registerRequest(data) {
    try {
        let object_data = data
        if (data.model && data.brand) {
            object_data = {
                ...data,
                driver: {
                    model: data.model,
                    brand: data.brand
                }
            }

            delete object_data.model
            delete object_data.brand
        }

        const response = await api.post('user', object_data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return { data: response.data, error: null }
    }
    catch (error) {

        return {
            error: error.response.data.message,
            data: null
        }
    }
}

async function SignOut() {
    try {
        const response = await api.delete('auth')

        return response.data
    }
    catch (error) {

        return {
            error: error.response.data.message
        }
    }
}

async function recoverUserInformation() {

    const response = await api.get('auth/me')

    if (Object.keys(response.data).length === 0) {
        return null;
    }
    return response.data
}

export { signInRequest, recoverUserInformation, SignOut, registerRequest };
