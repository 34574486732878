function NotFound() {
  return (
    <div
      style={{
        marginTop: '15%'
      }}
    >
      <h1>404 - Not Found!</h1>
    </div>
  );
}

export default NotFound;
