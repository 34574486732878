import "react-chat-elements/dist/main.css";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import LoadingIcon from "@mui/icons-material/Pending";
import BackIcon from "@mui/icons-material/ChevronLeft";
import { MessageBox } from "react-chat-elements";

import "../components/css/ChatComponent.css";
import Navbar from "../components/Navbar";
import { AuthContext } from "../contexts/AuthContext";
import { api } from "../services/api";

export default function ChatPage() {
  const context = useContext(AuthContext);
  const location = useLocation();
  const [msg, setMsg] = useState("");
  const { ride } = location.state;
  const [messages, setMessages] = useState([]);
  const timer = useRef(null);
  const navigate = useNavigate();

  const getMessages = useCallback(() => {
    api
      .request({
        url: `rides/${ride.id}/chat`,
        method: "get",
      })
      .then((res) => {
        setMessages(res.data);
      });
  }, [ride]);

  useEffect(() => {
    getMessages();
    timer.current = setInterval(getMessages, 2000);
    return () => clearInterval(timer.current);
  }, [ride]);

  const receiver_id = useMemo(
    () => (context.user.id == ride.riderId ? ride.passenger.id : ride.rider.id),
    [ride, context]
  );

  const [loading, setLoading] = useState(false);
  const sendMessage = () => {
    setLoading(true);
    api
      .post("chat", {
        ride_id: ride.id,
        sender_id: context.user.id,
        receiver_id,
        content: msg,
      })
      .then(() => {
        setMsg("");
        getMessages();
      })
      .finally(setLoading(false));
  };

  return (
    <>
      <Navbar marginTop={false} centralize={true} />
      <div
        style={{
          padding: "0 4.5rem !important",
          display: "flex",
          flexDirection: "column",
          height: "calc(95vh - 71px - 56px)",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", borderBottom: "1px solid #6356E5" }}>
          <button
            disabled={loading}
            onClick={() => {
              navigate(-1);
            }}
            style={{ backgroundColor: "transparent", border: "0" }}
          >
            <BackIcon color="primary" />
          </button>

          <h2
            style={{
              color: "#6356E5",
              margin: 0,
            }}
          >
            {context.user.id == ride.riderId
              ? ride.passenger.name
              : ride.rider.name}
          </h2>
        </div>

        <div style={{ flexGrow: 1, overflow: "auto", padding: "10px 0px" }}>
          {messages &&
            messages.map((message) => (
              <MessageBox
                key={message.id}
                date={new Date(message.createdAt)}
                position={
                  message.sender_id == context.user.id ? "right" : "left"
                }
                type={"text"}
                text={message.content}
              />
            ))}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <textarea
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            className="msg-input"
            placeholder="Message"
            rows={1}
            disabled={loading}
          ></textarea>
          <button
            disabled={loading}
            onClick={sendMessage}
            style={{ backgroundColor: "transparent", border: "0" }}
          >
            {loading ? <LoadingIcon /> : <SendIcon color="primary" />}
          </button>
        </div>
      </div>
    </>
  );
}
