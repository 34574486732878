import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { api } from '../services/api';
import { StyledSubmit } from '../pages/profile/styles';

export const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        // Crie o token do cartão
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });


        await api.patch('user/card', {
            token: paymentMethod.id
        })

        setLoading(false);

        if (error) {
            setError(error.message);
        } else {
        }
    };



    return (
        <form onSubmit={handleSubmit}>
            <CardElement
        
                className="card"
                options={{
                    style: {
                        base: {
                            backgroundColor: "white",
                        }
                    },
                }}
            />
            {error && <div>{error}</div>}
            <StyledSubmit style={{
                marginTop: '1rem',

            }} type="submit" disabled={!stripe || loading}>
                {loading ? 'Processando...' : 'Adicionar cartão'}
            </StyledSubmit>
        </form>
    );
};