import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import carPurpleImg from "../assets/images/carPurpleImg.png";
import Container from "../components/Container";
import EastIcon from '@mui/icons-material/East';
import OnboardingCarousel from "../components/OnboardingCarousel";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const OnboardingStyled = styled.div`
  img {
    width: 100%;
  }

  margin-top: 15%;
`;


function Onboarding() {
  const context = useContext(AuthContext)
  localStorage.setItem('see', true)
  return (
    <Container>
      <OnboardingStyled>
        <div
          style={{
            padding: '2rem',
            width: '7rem',
            borderRadius: '15px',
            boxShadow:'0px 16px 30px rgba(0, 0, 0, 0.06)'
          }}
        >
          <img src={carPurpleImg} 
              style={{
                width: '3rem',
              }}
          />
        </div>
        <OnboardingCarousel/>

        <Link
          to={context.isAuthenticated ? '/home' : '/login'}
          id="onboarding-btn"
        >
          Divide rides
          <EastIcon  />
        </Link>
      </OnboardingStyled>
    </Container>
  );
}

export default Onboarding;
