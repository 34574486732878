import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import React from "react";
import ReactDOM from "react-dom/client";
import { SWRConfig } from "swr/_internal";

import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const theme = createTheme({
  palette: {
    primary: {
      main: '#6356E5',
      light: '#7367e8',
      dark: '#574cca',
      contrastText: '#FFFFFF'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        provider: () => new Map()
      }}
    >
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>

    </SWRConfig>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

