import styled from "styled-components";

export const AvatarContainer = styled.div`
  width: 100%;
  margin-top:1rem;
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 1.5rem;
    margin-top: .1rem;
    color: black;
    font-weight: 500;
  }
`;

export const StyledImg = styled.img`
  height: auto;
  width: 90px;
  border-radius: 100%;
  overflow: hidden;
`;


export const StyledSubmit = styled.button`
    color: #AA56E5;
    cursor: pointer;
    height: 40px !important;
    width: 150px;
 
    border: 2px solid #AA56E5;
    border-radius: 7px;
`;