import React, { useState } from "react";
import "./App.css";

import SplashScreen from "./components/SplashScreen";
import Container from "./components/Container";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NavMenu from "./components/navmenu/navmenu";

function App() {
  const [showApp, setShowApp] = useState(false);

  const handleSplashScreenFinish = React.useCallback(() => {
    setShowApp(true);
  }, [showApp]);

  return (
    <div className="app">
      {!showApp && <SplashScreen onFinish={handleSplashScreenFinish} />}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme="colored"
      />
      {showApp && (
        <Container>
          <main>
            <BrowserRouter>
              <AppRoutes />
              <div style={{ marginBottom: "2rem" }}>&nbsp;</div>
              <NavMenu fixed={false} />
            </BrowserRouter>
          </main>
        </Container>
      )}
    </div>
  );
}

export default App;
