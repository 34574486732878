import { Container, Typography } from "@mui/material";
import { useState } from "react";
import { StyledButton } from "./styles";
import { CSSTransition } from "react-transition-group";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./GoogleInput.css";
import { Image } from "@mui/icons-material";

export function GoogleInput({ label, children, setHandleOtherInputs, showInputs, sx }) {
    const handleButtonClick = () => {
        setHandleOtherInputs(!showInputs)
    };

    return (
        <Container
            sx={{
                marginBottom: '1rem',
                boxShadow: "0px 2px 12px 0px rgba(66, 68, 90, 0.2)",
                width: "100%",
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                ...sx
            }}
        >
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: ".6rem",
                }}
            >
                <Typography variant="body1" color="rgba(0, 0, 0, 0.9)" fontWeight={500}>
                    {label}
                </Typography>
                <StyledButton
                    onClick={handleButtonClick}
                    className={showInputs ? "rotated" : "unrotated"}
                >
                    <img
                        alt="Exibir ou ocultar opções"
                        src="/down-arrow.svg"
                        sx={{
                            width: "1.3rem",
                            opacity: 1,
                            color: "#998EED",
                        }}
                    />
                </StyledButton>
            </Container>

            <CSSTransition
                in={showInputs}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "1rem",
                    }}
                >
                    {children}
                </Container>
            </CSSTransition>
        </Container>
    );
}
