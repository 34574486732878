import { Typography } from "@mui/material";
import React from 'react';
import { useNavigate } from 'react-router-dom';

import "./css/RideComponent.css";


export function RideComponent({ ride, name_ride, haveRider, children }) {
    const navigate = useNavigate();

    return (
        <div className="fund">
            <div className="data_ride_user">

                <img style={{
                    width: '100px'
                }} src={ride.type == "PASSENGER" ? "/sport-car.png" : "/box-icon.png"} alt="" />
                <Typography variant="h6" gutterBottom>
                    {name_ride}
                </Typography>
                <Typography color={"#AA56E5"} fontFamily={"Poppins"} fontWeight={"400"} fontStyle={"normal"} gutterBottom>
                    {ride.start.address} - {ride.destination.address}
                </Typography>
                <Typography color={"#AA56E5"} fontFamily={"Poppins"} fontWeight={"400"} fontStyle={"normal"} gutterBottom>
                    Your ride is {haveRider ? 'confirmed' : 'scheduled'} to {new Date(ride.date).toDateString() + ' at ' + new Date(ride.date).toLocaleTimeString()}.
                </Typography>


                <div className="price_container_candidate">
                    <Typography>
                        US$ {ride.ticket.toFixed(2)}
                    </Typography>

                    <Typography>
                        {ride.miles.toFixed(2)} Miles
                    </Typography>
                </div>

                {haveRider && <button
                    style={{ borderRadius: "10px" }}
                    onClick={() => { navigate('/chat', { state: { ride } }) }}
                    className="btn_action_candidate_first"
                >
                    Chat
                </button>}

                {children}
            </div>

        </div>
    )
}