import axios from "axios";
import { parseCookies } from "nookies";

function getAPIClient(ctx) {

    const api = axios.create({
        //baseURL: 'https://ride.fuway.com.br/v1/api/'
        //baseURL: 'http://18.236.131.67/v1/api/'
        baseURL: process.env.REACT_APP_API_URL
    });

    api.interceptors.request.use(config => {
        return config;
    });


    const rideTokenStoraged = localStorage.getItem('ride-token-storaged')
    console.log("rideTokenStoraged", rideTokenStoraged)
    if (rideTokenStoraged) {
        api.defaults.headers['Authorization'] = `Bearer ${rideTokenStoraged}`;
    }


    return api;
}

export { getAPIClient };
