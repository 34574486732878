import styled from "styled-components";


export const Container = styled.div`
    max-width: 767px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
`;


export const StyledButton = styled.button`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #1976d2;
    color: #fff;
    font-size: 14px;
    margin: 1rem 0;
    cursor: pointer;
`;