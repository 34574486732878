import React from "react";
import { AuthContext } from "../../contexts/AuthContext";

export default function SignOut() {
    const context = React.useContext(AuthContext)

    React.useEffect(() => {
        logout()
    }, [])

    const logout = async () => {
        await context.signOut()
    }

    return (
        <div>
            <h1>...</h1>
        </div>
    );
}