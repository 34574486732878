import styled from "styled-components";

export const StyledButton = styled.button`
background: rgba(170, 86, 229, 0.08);
border: none;
width: 3.6rem;
height: 3rem;
border-radius:16px;
display:flex;
justify-content:center;
align-items:center;
img {


}
`;