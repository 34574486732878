import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Navbar from '../../components/Navbar';
import { AuthContext } from '../../contexts/AuthContext';
import { StyledButton } from './styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ride.com/">
        Ride
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function SignIn() {
  const context = React.useContext(AuthContext)
  const [remember_me, setRememberMe] = React.useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('email') == null) {
      return toast.error('Email is required')
    } else if (data.get('password') == null) {
      return toast.error('Password is required')
    }

    const { error } = await context.signIn({
      email: data.get('email'),
      password: data.get('password'),
      remember_me
    })

    console.log(error)
    if (Array.isArray(error)) {
      toast.error(error[0])
      return
    }

    console.log("Comming here")
    if (error) {
      console.log(error)
      toast.error(error)
      return;
    }

    return navigate('/home')
  };


  return (
    <>
      <Navbar
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" alignSelf='flex-start' color={'gray'} fontWeight={'light'}>
            Sign in
          </Typography>
          <Box component="form" onSubmit={async (e) => await handleSubmit(e)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              onChange={() => setRememberMe(!remember_me)}
              name='remember_me'
              label="Remember me"
            />
            <StyledButton
              type="submit"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </StyledButton>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Box textAlign={'center'} display={'flex'} flexDirection={'column'}>
                  <Link href="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}