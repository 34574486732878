import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import Person4Icon from '@mui/icons-material/Person4';
import SearchIcon from '@mui/icons-material/Search';
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useUserisDriver from "../../hooks/useUserisDriver";
import "./navmenu.css";

function NavMenu({ fixed = true }) {
    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const isRider = useUserisDriver();
    const path = location.pathname;

    if (path === '/register' || path === '/register-driver' || path === '/logout' || path === '/login' || path === '/') {
        return null;
    }

    return (
        <BottomNavigation
            sx={{
                position: 'fixed',
                background: 'white',
                width: '100%',
                right: 0,
                bottom: 0,
            }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
            <BottomNavigationAction
                label="Home"
                onClick={() => {
                    navigate('/home');
                }}
                icon={<HomeIcon />}
                className={path === '/home' ? 'selected-nav' : ''}
            />
            <BottomNavigationAction
                label="Ride"
                onClick={() => {
                    navigate('/rides');
                }}
                icon={<SearchIcon />}
                className={path === '/rides' ? 'selected-nav' : ''}
            />
            {isRider && (
                <BottomNavigationAction
                    label="Receipts"
                    onClick={() => {
                        navigate('/rider');
                    }}
                    icon={<ListIcon />}
                    className={path === '/rider' ? 'selected-nav' : ''}
                />
            )}
            <BottomNavigationAction
                label="Profile"
                onClick={() => {
                    navigate('/profile');
                }}
                icon={<Person4Icon />}
                className={path === '/profile' ? 'selected-nav' : ''}
            />
            <BottomNavigationAction
                label="Logout"
                onClick={() => {
                    navigate('/logout');
                }}
                icon={<LogoutIcon />}
                className={path === '/logout' ? 'selected-nav' : ''}
            />
        </BottomNavigation>
    );
}

export default NavMenu;
