import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const style = (isMobile) => ({
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80vw' : 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
});

export default function AcceptModal({
    open,
    setOpen = () => { },
    acceptFunction,
    accept_text = 'Yes',
    cancel_text = 'No',
    text = 'Do you confirm this Ride?',
    secondAcceptFunction
}) {
    const handleClose = () => setOpen(false);
    const isMobile = useMediaQuery('(max-width:600px)')
    const modalWidth = isMobile ? '80vw' : '400px';
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            style={{
                width: `${modalWidth} !important`,
            }}

            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style(isMobile)} >
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        {text}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '2rem'
                        }}
                    >
                        <Button sx={{
                            top: '0',
                            marginRight: '1rem',
                            backgroundColor: '#6356E5'
                        }} onClick={async () => await acceptFunction(true)} variant="contained">
                            {accept_text}
                        </Button>
                        <Button sx={{
                            top: '0',
                            marginRight: '5rem'
                        }} onClick={async () => {
                            if (secondAcceptFunction) {
                                await secondAcceptFunction()
                            } else {
                                await acceptFunction(false)
                            }
                        }} variant="outlined" color="error">
                            {cancel_text}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}