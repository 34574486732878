import React from "react";

import "./css/OnboardingCarousel.css"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
//Owl Carousel Settings
const options = {
  responsiveClass: true,
  nav: false,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  loop: true, 
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
};
class OnboardingCarousel extends React.Component {
  render() {
    return (
      <div
      style={{
        marginTop: '2rem'
      }}
      >
        <OwlCarousel className="slider-items owl-carousel" {...options}>
          <div className="item1" >
            <h1 
            style={{
              color: 'black',
              fontWeight: '500'
            }}
            >Divide Rides</h1>
            <p className="onboarding-text"> This is the way</p>
          </div>
          <div className="item2">
            <h1
            style={{
              color: 'black',
              fontWeight: '500'
            }}
            >Create Rides</h1>
            <p className="onboarding-text">Users can split a ride and share the costs</p>
          </div>
          <div className="item3">
            <h1
            style={{
              color: 'black',
              fontWeight: '500'
            }}
            >Make your choices</h1>
            <p className="onboarding-text">Book your trip on your time</p>
          </div>

          <div className="item4">
            <h1
            style={{
              color: 'black',
              fontWeight: '500'
            }}
            >Your way</h1>
            <p className="onboarding-text"> You have endless possibilities</p>
          </div>
        </OwlCarousel>
      </div>
    );
  }
}
export default OnboardingCarousel;