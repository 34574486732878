import { Routes, Route, Navigate } from "react-router-dom";
import Onboarding from "../pages/Onboarding";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import SignIn from "../pages/login/login";
import React from "react";
import { AuthContext } from "../contexts/AuthContext";
import SignOut from "../pages/signOut/signOut";
import SignUp from "../pages/signUp/signUp";
import Profile from "../pages/profile/profile";
import ViewRides from "../pages/view_rides";
import RidesRider from "../pages/rides_rider";
import ChatPage from "../pages/chat";
import CreateRide from "../pages/create_ride/create_ride";
import SignUpDriver from "../pages/signUp/signUpDriver";

function AppRoutes() {
  const alreadysee = localStorage.getItem("see");
  const context = React.useContext(AuthContext);

  return (
    <Routes>
      {context.isAuthenticated ? (
        <>
          <Route path="/logout" element={<SignOut />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rides" element={<ViewRides />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/rider" element={<RidesRider />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/maps/:lat/:lng" element={<CreateRide />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<SignIn />} />
        </>
      )}
      {alreadysee ? (
        <Route path="/" element={<SignIn />} />
      ) : (
        <Route path="/" element={<Onboarding />} />
      )}
      <Route path="/register" element={<SignUp />} />
      <Route path="/register-driver" element={<SignUpDriver />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
}

export default AppRoutes;
