import { Container } from "@mui/material";

export default function OptionButton({ first_opt, second_opt, handleState, state }) {
    return (
        <Container>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                padding: "0 4.5rem",
                marginTop: "2rem"
            }}>
                <div
                    id="first"
                    onClick={() => handleState(true)}
                    style={{
                        backgroundColor: state ? "#6356E5" : "#fff",
                    }}
                >
                    <h2 style={{
                        fontSize: "1rem",
                        color: state ? "#fff" : "#6356E5",
                    }}>{first_opt}</h2>
                </div>
                <div
                    id="second"
                    onClick={() => handleState(false)}
                    style={{
                        backgroundColor: state ? "#fff" : "#6356E5",
                    }}
                >
                    <h2 style={{
                        fontSize: "1rem",
                        color: state ? "#6356E5" : "#fff",
                    }}>{second_opt}</h2>
                </div>
            </div>
        </Container>
    )
}