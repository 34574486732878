import { Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

import { CandidateComponent } from "../components/CandidateComponent";
import Navbar from "../components/Navbar";
import OptionButton from "../components/OptionButton";
import { RideComponent } from "../components/RideComponent";
import WheelAndHamster from "../components/WheelAndHamster";
import "../components/css/OptionButton.css";
import { AuthContext } from "../contexts/AuthContext";
import { api } from "../services/api";

export default function ViewRides() {
  const context = useContext(AuthContext);
  const [riderPage, setRiderPage] = useState(true);
  const { data, error, isLoading } = useSWR(
    "my_rides",
    async (url) => {
      const response = await api.request({
        url: "ride/passenger",
        method: "get",
      });

      if (Object.keys(response.data).length === 0) {
        throw new Error("Error handling");
      }

      return response.data;
    },
    {
      errorRetryCount: 5,
    }
  );

  const {
    data: data_canditates,
    error: error_canditates,
    isLoading: isLoading_candidates,
  } = useSWR(
    "ride/candidates/my",
    async (url) => {
      const response = await api.request({
        url,
        method: "get",
      });

      return response.data;
    },
    {
      errorRetryCount: 5,
    }
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (error || error_canditates) {
      console.log(error);
      toast.error(error.response.data.response.message);
      navigate("/home");
      return;
    }
  }, []);

  const handleAccept = async (ride_id, candidate_id) => {
    try {
      await api.request({
        url: `ride/accept/candidate/${ride_id}/${candidate_id}`,
        method: "patch",
      });

      window.location.reload();
    } catch (error) {
      if (error.response.data.response.message) {
        toast.error(error.response.data.response.message);
        return;
      }
    }
  };

  const handleReject = async (ride_id, candidate_id) => {
    try {
      await api.request({
        url: `ride/reject/candidate/${ride_id}/${candidate_id}`,
        method: "delete",
      });

      window.location.reload();
    } catch (error) {
      console.log(error);
      if (error.response.data.response.message) {
        toast.error(error.response.data.response.message);
      } else {
        toast.error("Rejected");
      }
    }
  };

  return (
    <>
      <Navbar marginTop={false} centralize={true} />
      <Container
        style={{
          padding: "0 4.5rem !important",
          justifySelf: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
          }}
          variant="h5"
          gutterBottom
        >
          View My Rides
        </Typography>
        <OptionButton
          state={riderPage}
          first_opt={"View my rides"}
          second_opt={"Accept The Rider"}
          handleState={setRiderPage}
        />
        {isLoading && isLoading_candidates && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "7rem",
            }}
          >
            <WheelAndHamster />
          </div>
        )}

        {riderPage &&
          data != null &&
          data.passenger &&
          data.passenger.length > 0 &&
          data.passenger
            .sort((a, b) => b.id - a.id)
            .map((ride, idx) => (
            <RideComponent
              haveRider={Boolean(ride.rider)}
              name_ride={ride.rider ? ride.rider.name : "Waiting for rider..."}
              key={idx}
              ride={ride}
            />
          ))}
        {!riderPage &&
          data_canditates &&
          data_canditates.length > 0 &&
          data_canditates.map((ride, idx) => {
            return ride.candidates.map((candidate, idxx) => {
              return (
                <CandidateComponent
                  first_handle={handleAccept}
                  second_handle={handleReject}
                  key={idxx + idx}
                  candidate={candidate}
                  ride_data={ride.ride_data}
                />
              );
            });
          })}
      </Container>
    </>
  );
}
