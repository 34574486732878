import { useNavigate, useParams } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import { useState } from "react"
import { api } from "../../services/api"
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "./create_ride.css"
import OptionButton from "../../components/OptionButton";
import Search from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import AcceptModal from "../../components/modal/TransictionModal";
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export function getSuggestions(searchTerm, setResults) {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchTerm)}&key=${API_KEY}&region=us`;
  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'OK') {
        if (Array.isArray(data.results) && data.results.length > 0) {
          const formattedResults = data.results.map(result => {
            const formattedAddress = result.formatted_address.replace(/,\s*Brazil$/i, ''); // Remove "Brazil" from the end of the address
            return { ...result, formatted_address: formattedAddress };
          });
          setResults(formattedResults);
        }
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
}
function CreateRide() {
  const params = useParams()
  const [placeName, setPlaceName] = useState('');
  const [date, setDate] = useState(new Date())
  const [startAddress, setStartAddress] = useState()
  const [destinationAddress, setDestinationAddress] = useState()
  const [destinationResults, setDestinationResults] = useState([])
  const [inUse, setInUse] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [inUseDestination, setInUseDestination] = useState(false);
  const [results, setResults] = useState([]);
  const [distance, setDistance] = useState('0');
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries: [
      'places'
    ]
  });

  const navigate = useNavigate()
  async function calculateDistance() {

    function transformValue(value) {
      return (value).toFixed(2)
    }
    try {
      const response = await api.post('calculate-distance', {
        start: startAddress,
        destination: destinationAddress,
      })
      console.log(response.data.miles_number)
      const distance = transformValue(response.data.miles_number / 1608)
      
      setDistance(distance)
    } catch (error) {
      handleDistanceCalculationError(error);
    }
  }

  function handleDistanceCalculationError(error) {
    console.error('Error calculating distance:', error);
  }

  useEffect(() => {
    if (startAddress && destinationAddress) {
      calculateDistance();
    }
  }, [startAddress, destinationAddress])

  useEffect(() => {
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng)
      };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (Array.isArray(results) && results.length > 0) {
            setResults(results)
          }
          if (results[0]) {
            setStartAddress(results[0].formatted_address);
          }
        }
      });
    }
  }, [isLoaded, params.lat, params.lng]);

  const [opt, setOpt] = useState(true)

  const handleSubmitAction = async (accept) => {
    try {
      console.log("debug", {
        startAddress,
        destinationAddress,
        accept
      })

      if (!accept) {
        setOpenPopup(false)
        return
        // createGoogleCalendarEvent(date, 'Your ride', 'You have a ride scheduled', startAddress + '-' + destinationAddress)
      }
      await api.post('ride', {
        start: {
          zip: 'NOT-IMPLEMENTED',
          address: startAddress
        },
        destination: {
          zip: 'NOT-IMPLEMENTED',
          address: destinationAddress
        },
        date: date.getTime(),
        type: opt ? 'PASSENGER' : 'PACKAGE',
      })
      console.log("debug", {
        accept
      })
      toast.success('Ride created successfully!');

      return navigate('/rides')
    } catch (error) {
      toast.error('Error creating ride!');
      return;
    }
  }

  const handleConfirmRide = async () => {

    if (!startAddress || !destinationAddress || !date) {
      toast.error('Please fill all fields!');
      return
    }



    setOpenPopup(true)
  }



  return (
    <>
      <AcceptModal secondAcceptFunction={() => {
        setOpenPopup(false)
      }} acceptFunction={handleSubmitAction} open={openPopup} setOpen={openPopup} />
      <div className="main-container">
        <h1>Ask a Ride</h1>
        <OptionButton state={opt} first_opt={"Person"} second_opt={"Package"} handleState={setOpt} />

        <div className="form">
          <div className="input">
            <div className="res">
              <input
                type="text"
                style={{
                  zIndex: inUse ? 10 : 0
                }}
                onFocus={
                  () => setInUse(true)
                }
                onChange={(e) => {
                  setStartAddress(e.target.value)
                  getSuggestions(e.target.value, setResults)
                }}
                value={startAddress}
                placeholder={startAddress ? startAddress : "Starting point"} />
              {
                inUse &&
                results.length > 0 &&
                <div className="options">
                  {
                    results.map((result, index) => {
                      return (
                        <p key={index} onClick={() => {
                          setStartAddress(result.formatted_address)
                          setInUse(false)
                        }}>{result.formatted_address}</p>
                      )
                    })
                  }
                </div>
              }
            </div>
            {
              startAddress ?
                <CloseIcon
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setStartAddress('')
                  }}
                /> :
                <Search />
            }
          </div>

          <Box sx={{
            position: 'relative',
            zIndex: inUse || inUseDestination ? -9 : 1
          }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Date and Time"
                defaultValue={dayjs(date)}
                onChange={(date) => {
                  setDate(date ? date.toDate() : undefined)
                }}
                sx={{
                  zIndex: 0,
                  width: '100%',
                  height: '3rem',
                  background: 'white',
                  marginTop: '2rem',
                  fontWeight: '400',
                  borderRadius: '5px',
                }}
              />
            </LocalizationProvider>

          </Box>

          <div className="input" style={{
            position: 'relative',
            zIndex: inUse ? -9 : 1
          }}>
            <div className="res">
              <input
                type="text"
                onFocus={
                  () => setInUseDestination(true)
                }
                onChange={(e) => {
                  setDestinationAddress(e.target.value)
                  getSuggestions(e.target.value, setDestinationResults)
                }}
                value={destinationAddress}
                placeholder={destinationAddress ? destinationAddress : "Destination"} />
              {
                inUseDestination &&
                destinationResults.length > 0 &&
                <div className="options">
                  {
                    destinationResults.map((result, index) => {
                      return (
                        <p key={index} onClick={() => {
                          setDestinationAddress(result.formatted_address)
                          setInUseDestination(false)
                        }}>{result.formatted_address}</p>
                      )
                    })
                  }
                </div>
              }
            </div>
            {
              destinationAddress ?
                <CloseIcon
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setDestinationAddress('')
                  }}
                /> :

                <Search />
            }
          </div>

          <div className="input" style={{
            position: 'relative',
            zIndex: inUse || inUseDestination ? -9 : 1
          }}>
            <p>Distance</p>
            <p>{distance} miles</p>
          </div>

          <div className="input" style={{
            position: 'relative',
            zIndex: inUse || inUseDestination ? -9 : 1
          }}>
            <p>Estimate Value</p>
            <p>US$ {(distance * 0.25).toFixed(2)} </p>
          </div>

          <button
            className="buton"
            onClick={handleConfirmRide}
          >Confirm</button>
        </div>
      </div>
    </>
  )
}

export default CreateRide;
