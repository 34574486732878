import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import EditIcon from '@mui/icons-material/Edit';
import { Button, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from '@mui/material/styles';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { serialize } from 'object-to-formdata';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

import { CardComponent } from "../../components/CardComponent";
import Container from "../../components/Container";
import { GoogleInput } from "../../components/GoogleInput";
import Navbar from "../../components/Navbar";
import { PaymentForm } from "../../components/Payment";
import { AuthContext } from "../../contexts/AuthContext";
import useUserisDriver from "../../hooks/useUserisDriver";
import { api } from "../../services/api";
import { AvatarContainer, StyledImg, StyledSubmit } from "./styles";

const stripePromise = loadStripe('pk_test_51N6e2lDZyl6KJucmT55rIkJk5hMlMMLuP9RJa3Exwi7PIKFTFf6KPGJxee2zu8R6UhHzySesSMiylkUfXi6geWKG00FfKpBgSQ')

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function Profile() {
    const context = React.useContext(AuthContext);

    const [data, setData] = useState(null)
    useEffect(() => {
        api.request({
            url: 'user/cards',
            method: 'get'
        }).then(res => { setData(res.data.data) })
    }, [])

    const [inUseState, setInUseState] = React.useState({
        profile: false,
        driver: false,
        payment: false,
        bank: false,
        vehicle: false,
    })

    const handlePersonalDataUpdate = async () => {
        const name = document.getElementById('name').value;
        const password = document.getElementById('password').value;
        await api.patch('user', {
            name,
            password: password ? password : undefined
        })

        await context.updateProfile()

        toast.success("Personal data updated successfully")
    }

    const handleBankDataUpdate = async () => {
        const account = document.getElementById('account_number').value;
        const routing = document.getElementById('routing_number').value;
        await api.patch('user', {
            account,
            routing,
        })

        await context.updateProfile()

        toast.success("Bank data updated successfully")
    }

    const handleVehicleDataUpdate = async () => {
        const model = document.getElementById('model').value;
        const brand = document.getElementById('brand').value;
        const year = document.getElementById('year').value;
        const miles = document.getElementById('miles').value;
        const color = document.getElementById('color').value;

        if (Number(year) == 0) return toast.error("Year and miles must be greater than 0")


        await api.patch('user', {
            driver: {
                model,
                brand,
                year: Number(year),
                miles: !miles ? undefined : Number(miles),
                color
            }
        })

        await context.updateProfile()

        toast.success("Driver's data updated successfully")
    }

    const [licensePicture, setLicensePicture] = useState(null);

    const handleDriverDataUpdate = async () => {
        const expiration = document.getElementById('license_expiration_date').value;
        const issueDate = document.getElementById('license_issue_date').value;
        const driver_license = document.getElementById('driver_license').value;

        const dayjs = require('dayjs')
        const now = dayjs();

        if (now.isAfter(expiration) || now.isBefore(issueDate)) return toast.error("Expiration or Issue date invalid")

        await api.patch('user', serialize({
            driver: {
                license_expiration_date: expiration,
                license_issue_date: issueDate,
                driver_license,
            },
            license_picture: licensePicture,
        }))

        await context.updateProfile()

        toast.success("Driver's data updated successfully")
    }



    const handleTradeBankAccountData = (state) => {
        setInUseState({
            profile: false,
            driver: false,
            payment: false,
            bank: state,
            vehicle: false,
        })
    }

    const handleTradePersonalDate = (state) => {
        setInUseState({
            profile: state,
            driver: false,
            payment: false,
            bank: false,
            vehicle: false,
        })
    }

    const handleTradeVehicleData = (state) => {
        setInUseState({
            profile: false,
            driver: false,
            payment: false,
            bank: false,
            vehicle: state,
        })
    }

    const handleTradeDriverDate = (state) => {
        setInUseState({
            profile: false,
            driver: state,
            payment: false,
            bank: false,
            vehicle: false,
        })
    }

    const handleTradePaymentDate = (state) => {
        setInUseState({
            profile: false,
            driver: false,
            bank: false,
            payment: state,
            vehicle: false,
        })
    }

    const handlePictureChange = async (event) => {
        const files = event.target.files
        if (files.length > 0) {
            console.log(context.user.picture);
            const newProfilePicture = files[0];
            await api.post(`user/${context.user.id}/picture`, serialize({ file: newProfilePicture }))
            await context.updateProfile()
            
            toast.success("Picture updated successfully")
        }
    }


    return (
        <>
            <Container>
                <Navbar marginTop={false} centralize={true} />

                <AvatarContainer>
                    <label htmlFor="file">
                        <Badge badgeContent={<EditIcon fontSize='12' />} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                            color="primary"
                        >
                            <StyledImg 
                            //src={licensePicture ? URL.createObjectURL(licensePicture) : process.env.REACT_APP_API_HOST + context.user.driver.license_picture}
                            src={context.user.picture ? process.env.REACT_APP_API_HOST + context.user.picture : "profile.jpeg"} />
                        </Badge>
                    </label>
                    <input id="file" type="file" accept="image/x-png,image/jpeg,image/gif" onChange={handlePictureChange} style={{ display: 'none' }} />
                    <br />
                    <h1>{context.user.name}</h1>
                </AvatarContainer>
                
                <GoogleInput showInputs={inUseState.driver} setHandleOtherInputs={handleTradeDriverDate} label={"Driver's Information"}>
                    <TextField
                        id="driver_license"
                        label="Driver License"
                        defaultValue={context.user.driver ? context.user.driver.driver_license : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="license_expiration_date"
                        label="Expiration Date"
                        defaultValue={context.user.driver ? context.user.driver.license_expiration_date : ''}
                        type='date'
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="license_issue_date"
                        label="Issue Date"
                        defaultValue={context.user.driver ? context.user.driver.license_issue_date : ''}
                        type='date'
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />


                    <Button
                        component="label"
                        variant="contained"
                        startIcon={(context.user.driver && context.user.driver.license_picture) || licensePicture
                            ? <CloudDoneIcon />
                            : <CloudUploadIcon />}
                    >
                        License Picture
                        <VisuallyHiddenInput type="file" accept="image/x-png,image/jpeg,image/gif" onChange={(e) => {
                            const files = e.target.files
                            if (files.length > 0) {
                                setLicensePicture(files[0])
                            }
                        }} />
                    </Button>

                    {(licensePicture || (context.user.driver && context.user.driver.license_picture)) ?
                        <img
                            crossorigin="anonymous"
                            style={{ objectFit: 'contain', marginTop: '1rem', marginBottom: '1rem', height: 100, width: 100 }}
                            height={100}
                            width={100}
                            src={licensePicture ? URL.createObjectURL(licensePicture) : process.env.REACT_APP_API_HOST + context.user.driver.license_picture}
                            alt=""
                        /> : <br />
                    }


                    <StyledSubmit onClick={handleDriverDataUpdate}>
                        UPDATE
                    </StyledSubmit>
                </GoogleInput>
                <GoogleInput showInputs={inUseState.vehicle} setHandleOtherInputs={handleTradeVehicleData} label={"Vehicle's Information"}>
                    <TextField
                        id="model"
                        label="Model"
                        defaultValue={context.user.driver ? context.user.driver.model : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="brand"
                        label="Brand"
                        defaultValue={context.user.driver ? context.user.driver.brand : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="year"
                        label="Year"
                        type="number"
                        defaultValue={context.user.driver ? context.user.driver.year : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="miles"
                        type="number"
                        label="Miles"
                        defaultValue={context.user.driver ? context.user.driver.miles : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <TextField
                        id="color"
                        label="Color"
                        defaultValue={context.user.driver ? context.user.driver.color : ''}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <StyledSubmit onClick={handleVehicleDataUpdate}>
                        UPDATE
                    </StyledSubmit>
                </GoogleInput>

                <GoogleInput
                    showInputs={inUseState.profile}
                    setHandleOtherInputs={handleTradePersonalDate}
                    label={"Personal Data"}
                >
                    <TextField
                        id="name"
                        label="New Name"
                        variant="outlined"
                        defaultValue={context.user.name}
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />
                    <TextField
                        label="email"
                        disabled={true}
                        defaultValue={context.user.email}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />
                    <TextField
                        id="password"
                        label="New Password"
                        type="password"
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <StyledSubmit onClick={handlePersonalDataUpdate}>
                        UPDATE
                    </StyledSubmit>
                </GoogleInput>

                <GoogleInput sx={{
                    marginBottom: '1rem'
                }} showInputs={inUseState.payment} setHandleOtherInputs={handleTradePaymentDate} label={"Payment Information"}>
                    {
                        data && data.map((card, idx) => <CardComponent card={card} key={idx} />)
                    }
                    <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements>
                </GoogleInput>

                <GoogleInput
                    showInputs={inUseState.bank} setHandleOtherInputs={handleTradeBankAccountData} label={"Bank Account Data"}>
                    <TextField
                        id="account_number"
                        label="Account number"
                        variant="outlined"
                        defaultValue={context.user.account_number}
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />
                    <TextField
                        id="routing_number"
                        label="Routing  number"
                        defaultValue={context.user.routing_number}
                        variant="outlined"
                        sx={{
                            marginBottom: "1rem",
                        }}
                    />

                    <StyledSubmit onClick={handleBankDataUpdate}>
                        UPDATE
                    </StyledSubmit>
                </GoogleInput>

                
                
            </Container >
        </>
    );
}
