import { Typography } from "@mui/material"
import "./css/CandidateComponent.css"

export function CandidateComponent({ candidate, ride_data, first_handle, second_handle }) {
    return (
        <div style={{
            marginBottom: "15vh",
        }}>
            <div className="back">
                <div
                    className="user_data"
                >
                    <img style={{
                        width: '100px'
                    }} src={ride_data.type =="PASSENGER"? "/sport-car.png":"/box-icon.png"} />
                    <h1>{candidate.name}</h1>
                    <Typography color={"white"} fontFamily={"Poppins"} fontWeight={"400"} fontStyle={"normal"} gutterBottom>
                        {ride_data.start.address} - {ride_data.destination.address}
                    </Typography>
                    <Typography color={"white"} fontFamily={"Poppins"} fontWeight={"400"} fontStyle={"normal"} gutterBottom>
                    The timestamp of this ride is {new Date(ride_data.date).toDateString() + ' at ' + new Date(ride_data.date).toLocaleTimeString()}.
                </Typography>
                    <div className="price_container_candidate">
                        <Typography color={'white'}>
                            US$ {ride_data.ticket.toFixed(2)}
                        </Typography>

                        <Typography color={'white'}>
                            {ride_data.miles.toFixed(2)} Miles
                        </Typography>
                       
                    </div>
                </div>

            </div>

            <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <button onClick={() => first_handle(ride_data.id, candidate.id)} style={{
                    borderRadius:second_handle ? "10px 0 0 10px" : "10px"
                }} className="btn_action_candidate_first">Accept</button>
                {
                    second_handle &&
                <button onClick={() => second_handle(ride_data.id, candidate.id)} className="btn_action_candidate_second">Not Accept</button>
                }
            </div>
        </div>
    )
}