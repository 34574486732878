import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Tooltip, Typography } from '@mui/material';
import "./css/CardComponents.css"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { api } from '../services/api';
import { toast } from 'react-toastify';
export const CardComponent = ({ card }) => {

    const removeCard = async () => {
        await api.delete(`user/cards/${card.id}`)
        toast.success("Card removed")
    }

    return (<div
        style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center"
        }}
    >
        <CreditCardIcon />
        <Typography variant="h6" gutterBottom>
            <p >{card.card.brand} - {card.card.last4}
                <Tooltip title="Remove">
                    <RemoveCircleOutlineIcon onClick={removeCard} sx={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: '1rem'
                    }} />
                </Tooltip>

            </p>

        </Typography>
    </div>)
} 