import React, { useEffect } from "react";
import styled from "styled-components";
import logoImg from "../assets/images/logo.png";
const SplashScreenStyled = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 30%;
  }

  h1 {
    margin-top: 20px;
    text-align: center;
  }
`;

function SplashScreen({ onFinish }) {
  useEffect(() => {
    setTimeout(() => {
      onFinish();
    }, 1000);
  }, [onFinish]);

  return (
    <SplashScreenStyled>
      <img src={logoImg} alt="Ride for you app logo" />

    </SplashScreenStyled>
  );
}

export default SplashScreen;